<template>
    <div class="booth-ad-content">
        <div class="header">趋势分析</div>
        <div class="chart-tab">
            <el-checkbox-group v-model="checkList">
                <el-checkbox :label="checkItem" v-for="checkItem in categoryList" :key="checkItem"></el-checkbox>
            </el-checkbox-group>
            <div class="data-content" id="searchAd"></div>
        </div>
        <div class="cut-off"></div>
        <div class="header area-header">
            地区分布
            <div class="focus-text">
                成交客户主集中在
                <span class="focus-city"
                      v-for="(customerItem, customerIndex) in customerList" :key="`customerList_${customerIndex}`">
                    {{customerItem}}<span v-if="customerIndex !== customerList.length - 1">、</span>
                </span>
            </div>
        </div>
        <div class="area-table">
            <el-table :data="areaList" border style="width: 100%; margin-top: 10px; flex: 1" size="medium"
                      :header-cell-style="{fontWeight: 'normal', height: '48px', color: '#666666', background: '#F5F5F5'}"
                      :cell-style="{fontSize: '14px',color: '#343434'}">
                <el-table-column align="center" label="排名" width="60">
                    <template slot-scope="scope">
                        <div class="ranking" v-if="scope.row.sort === 1 || scope.row.sort === 2 || scope.row.sort === 3">{{scope.row.sort}}</div>
                        <div v-else style="line-height: 24px">{{scope.row.sort}}</div>
                    </template>
                </el-table-column>
                <el-table-column prop="name" align="center" label="省份"></el-table-column>
                <el-table-column align="center" label="点击率">
                    <template slot-scope="scope">
                        {{scope.row.click}}%
                    </template>
                </el-table-column>
                <el-table-column align="center" label="转化率">
                    <template slot-scope="scope">
                        {{scope.row.conversion}}%
                    </template>
                </el-table-column>
                <el-table-column align="left" label="占比">
                    <template slot-scope="scope">
                        <div class="area-item">
                            <el-progress :percentage="scope.row.percentage" :show-text="false"></el-progress>
                            <span class="percentage">{{scope.row.value}}%</span>
                        </div>
                    </template>
                </el-table-column>
            </el-table>
            <el-pagination class="operation-pagination" style="margin: 20px 0;"
                           :current-page="listPages.currentPageNum"
                           :page-size="listPages.eachPageNum"
                           :total="listPages.total"
                           layout="prev, pager, next, jumper"
                           @current-change="handleCurrentChange">
            </el-pagination>
        </div>
    </div>
</template>

<script>
    export default {
        name: "SearchAd",
        data() {
            return {
                searchAd: null,
                checkList: ['展现次数', '点击率', '转化率'],
                categoryList: ['展现次数', '点击率', '转化率'],
                allSeries: [],
                customerList: [],
                // tabList: [
                //     {
                //         id: 'show_num_search',
                //         name: '展现次数',
                //     },
                //     {
                //         id: 'click_rate_search',
                //         name: '点击率',
                //     },
                //     {
                //         id: 'conversion_rate_search',
                //         name: '转化率',
                //     }
                // ],
                //地区分布
                areaList: [],
                //分页
                listPages: {
                    currentPageNum: 1,
                    eachPageNum: 10,
                    total: 0,
                },
                denominator: null
            }
        },
        mounted(){
            this.getSearchAdInfo();
            this.getAreaList()
            this.searchAd = this.$echarts.init(document.getElementById('searchAd'));

            let resizeTimer = null;
            window.onresize = () => {
                clearTimeout(resizeTimer);
                resizeTimer = setTimeout(() => {
                    this.searchAd.resize()
                }, 100)
            }
        },
        computed: {
            series() {
                return this.allSeries.filter(item => {
                    return this.checkList.includes(item.name)
                })
            }
        },
        watch: {
            series(val) {
                this.getTrendECharts()
            }
        },
        methods: {
            // 获取eChart图表
            getSearchAdInfo() {
                this.$http.axiosGet(this.$api.advTrendAnalysis, (res) => {
                    if (res.code === 200) {
                        this.allSeries = [
                            {
                                name: '展现次数',
                                type: 'line',
                                data: res.data.potential_client,
                                smooth: true,
                                yAxisIndex: 1,
                                itemStyle: {
                                    normal: {
                                        color: "#E43A3C",
                                    }
                                },
                            },
                            {
                                name: '点击率',
                                type: 'line',
                                data: res.data.click_rate,
                                smooth: true,
                                itemStyle: {
                                    normal: {
                                        color: "#26C6DA",
                                    }
                                },
                            },
                            {
                                name: '转化率',
                                type: 'line',
                                data: res.data.tran_rate,
                                smooth: true,
                                itemStyle: {
                                    normal: {
                                        color: "#1E88E5",
                                    }
                                },
                            }
                        ];
                        this.getTrendECharts()
                    } else {
                        this.$message.warning(res.msg)
                    }
                }, (err) => {
                    console.log(err)
                })
            },
            // 趋势图
            getTrendECharts() {
                this.searchAd.setOption({
                    xAxis: {
                        type: 'category',
                        data: ['01:00', '02:00', '03:00', '04:00', '05:00', '06:00', '07:00', '08:00', '09:00', '10:00', '11:00', '12:00',
                            '13:00', '14:00', '15:00', '16:00', '17:00', '18:00', '19:00', '20:00', '21:00', '22:00', '23:00', '24:00' ],
                        boundaryGap: false,
                        axisLabel: {
                            textStyle: {
                                color: '#666'
                            },
                            align: 'center'
                        },
                        axisLine: {
                            lineStyle: {
                                color: '#F3F6FA'
                            }
                        },
                    },
                    yAxis: [{
                        type: 'value',
                        axisLabel: {
                            show: true,
                            textStyle: {
                                color: '#666'
                            }
                        },
                        axisLine: {
                            lineStyle: {
                                color: '#F3F6FA'
                            }
                        },
                        splitLine: {
                            lineStyle: {
                                color: '#F3F6FA'
                            }
                        }
                    },{
                        type: 'value',
                        position: 'right',
                        axisLabel: {
                            show: true,
                            textStyle: {
                                color: '#000'
                            }
                        },
                        axisLine: {
                            lineStyle: {
                                color: '#F3F6FA'
                            }
                        },
                        splitLine: {
                            lineStyle: {
                                color: '#F3F6FA'
                            }
                        }
                    }],
                    tooltip: {
                        trigger: 'axis',
                        axisPointer: {
                            type: 'cross',
                            label: {
                                backgroundColor: '#6a7985'
                            }
                        },
                        backgroundColor: '#fff',
                        textStyle: {
                            color: '#666',
                        },
                        borderColor: '#DCDCDC',
                        borderWidth: 2,
                        formatter: (params) => {
                            let res = params[0].name;
                            for (let i = 0; i < params.length; i++) {
                                if (params[i].seriesName === '点击率' || params[i].seriesName === '转化率') {
                                    res += '<br/>' + params[i].marker + params[i].seriesName + ' : ' + params[i].value + '%';
                                } else {
                                    res += '<br/>' + params[i].marker + params[i].seriesName + ' : ' + params[i].value;
                                }
                            }
                            return res
                        }
                    },
                    legend: {
                        icon: 'circle',
                        // data: this.checkList,
                        bottom: 0,
                        selectedMode: false
                    },
                    grid: {
                        top: 20,
                        left: 80,
                        right: '5%',
                        bottom: 80,
                    },
                    series: this.series
                }, true);
            },
            // 获取地区分布数据
            getAreaList() {
                let params = {
                    page: this.listPages.currentPageNum,
                    limit: this.listPages.eachPageNum
                }
                this.$http.axiosGetBy(this.$api.advAreaAnalysis, params, (res) => {
                    if (res.code === 200) {
                        if (this.listPages.currentPageNum === 1) {
                            this.denominator = res.data.list[0].percentage;
                        }
                        this.customerList = res.data.maximum_city
                        let tmpArr = [];
                        for (let i in res.data.list) {
                            let obj = {
                                click: res.data.list[i].click,
                                conversion: res.data.list[i].conversion,
                                name: res.data.list[i].name,
                                sort: res.data.list[i].sort,
                                percentage: Number((res.data.list[i].percentage / this.denominator * 100 ).toFixed(2)),
                                value: Number((res.data.list[i].percentage))
                            };
                            tmpArr.push(obj);
                        }
                        this.areaList = tmpArr;
                        this.listPages.total = res.data.total
                    } else {
                        this.$message.warning(res.msg)
                    }
                }, (err) => {
                    console.log(err)
                })
            },
            //分页
            handleCurrentChange(val) {
                this.listPages.currentPageNum = val
                this.getAreaList()
            }
        }
    }
</script>

<style scoped lang="scss">
    .booth-ad-content {
        /*margin: 0 20px;*/
        .header {
            margin: 0 20px;
            font-size: 16px;
            font-family: PingFang SC;
            font-weight: bold;
            color: #343434;
        }
        .area-header {
            display: flex;
            align-items: flex-end;
            margin: 20px;
            .focus-text {
                font-size: 14px;
                color: #343434;
                margin-left: 13px;
                font-weight: 400;
                .focus-city {
                    font-size: 14px;
                    color: #FF0000;
                }
            }
        }
        .chart-tab {
            margin: 20px 20px 15px;
            display: flex;
            flex-direction: column;
            .data-content {
                height: 330px;
                margin-top: 30px;
            }
            // 多选框的颜色
            ::v-deep .el-checkbox-group {
                .el-checkbox {
                    .el-checkbox__input {
                        .el-checkbox__inner {
                            &:hover {
                                border-color: #FD4446;
                            }
                        }
                        &.is-focus {
                            .el-checkbox__inner {
                                border-color: #FD4446;
                            }
                        }
                    }
                    &.is-checked {
                        .el-checkbox__input {
                            &.is-checked {
                                .el-checkbox__inner {
                                    background-color: #FD4446;
                                    border-color: #FD4446;
                                }
                            }
                            &:hover {
                                border-color: #FD4446;
                            }
                        }
                        .el-checkbox__label {
                            color: #343434;
                            font-weight: 400;
                        }
                    }
                }
            }
        }
        .cut-off{
            width: 100%;
            height: 20px;
            background: #F7F9FB;
        }
        .area-table {
            margin: 0 20px;
            ::v-deep .el-table {
                .el-table__body-wrapper {
                    .el-table__row {
                        .ranking {
                            width: 24px;
                            height: 24px;
                            line-height: 24px;
                            text-align: center;
                            font-weight: 500;
                            background-size: cover;
                            display: inline-block;
                        }
                        &:first-child {
                            .ranking {
                                background: url("../../../../../assets/images/dataCenter/one.png") no-repeat center;
                            }
                        }
                        &:nth-of-type(2) {
                            .ranking {
                                background: url("../../../../../assets/images/dataCenter/two.png") no-repeat center;
                            }
                        }
                        &:nth-of-type(3) {
                            .ranking {
                                background: url("../../../../../assets/images/dataCenter/three.png") no-repeat center;
                            }
                        }
                    }
                }
            }
        }
    }
    .area-item {
        display: flex;
        justify-content: space-between;
        align-items: center;
        .percentage {
            width: 40px;
            text-align: right;
        }
        ::v-deep .el-progress {
            width: 1%;
            flex: 1;
            padding: 0 10px;
        }
    }
</style>